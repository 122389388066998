import Request from "../Request";

export default class DistributeLog {
    public static baseUrl = '/core/api/v1/admin/b/limoid';

    public static distribution(data: any) {
        return Request({
            url: `${this.baseUrl}/preid/distribute`,
            method: "post",
            data
        });
    }

    public static getDistributionList(params: any) {
        return Request({
            url: `${this.baseUrl}/preid/distribute-log?type=IOTID`,
            method: "get",
            params
        });
    }
}
