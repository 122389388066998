
    import {Component, Vue} from 'vue-property-decorator';
    import {Foundation} from '@/utils/Foundation';
    import API_MerchantList from '@/utils/API/merchant/MerchantList';
    import {_distributeLog} from "@/utils/pageConfig/merchant/_distributeLog";
    import API_DistributeLog from "@/utils/API/merchant/DistributeLog";
    import distributionQrcode from "./components/distributionQrcode.vue";

    @Component({
        components: {
            distributionQrcode
        },
    })
    export default class PreidDistributeLog extends Vue {
        pageConfig: any = _distributeLog;
        tableData: any = [];
        showTable: boolean = false;
        total: number = 0;
        params: any = {
            current: 1,
            size: 20,
        };

        async mounted() {
            // 搜索框商户列表
            let options : any = [];
            let res : any = await API_MerchantList.getAllMerchant();
            res.map((e : any) => {
                options.push({label: e.coName, value: e.id});
            });

            this.pageConfig.search[0].options = options;
        }

        /*获取表格数据*/
        async getTableData() {
            if(!this.params.merchantId) return;
            let res: any = await API_DistributeLog.getDistributionList(this.params);
            this.tableData = (res.records && res.records.length > 0 &&
                res.records.map((e: any) => {
                    e._type = Foundation.findInArray(this.pageConfig.type, "value", e.type).label;
                    e._createTime = Foundation.dateFormat(e.createTime);
                    return e
                })) || [];
            this.total = res.total;
        }

        /*底部分页切换*/
        currentChange(pageNumber: number) {
            this.params.current = pageNumber;
            this.getTableData();
        }

        /*底部分页条数切换*/
        sizeChange(sizeNumber: number) {
            this.params.size = sizeNumber;
            this.getTableData();
        }

        /*查询*/
        handleSearch() {
            // TODO
            console.log((this.$refs.searchForm as any).form);

            this.showTable = true;
            let query: any = Foundation.resetParams((this.$refs.searchForm as any).form, true);
            this.params = {
                current: 1,
                size: 20,
                ...query,
            };
            this.getTableData();
        }

        /*分配二维码*/
        handleDistributionQrcode() {
            (this.$refs.distributionQrcode as any).show();
        }
    }
