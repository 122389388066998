//标识类型
import {integer} from "@/utils/validate";

const type: any = [
    {
        label: 'NORMAL',
        value: 0,
    },
    {
        label: 'IOTID',
        value: 1,
    }
];

export const _distributeLog = {
    //筛选
    search: [
        {
            type: 'el-select',
            label: '商户：',
            value: 'merchantId',
            props: {
                filterable: true,
                placeholder: '请选择商户',
                clearable: true
            },
            options: []
        }
    ],
    //列表
    table: [
        {
            type: 'index',
            label: '序号',
            width: '50'
        },
        {
            prop: 'extMerchantName',
            label: '商户名称'
        },
        {
            prop: 'count',
            label: '操作额度'
        },
        {
            prop: '_type',
            label: '标识类型'
        },
        {
            prop: 'extUserName',
            label: '分配人'
        },
        {
            prop: '_createTime',
            label: '分配时间'
        }
    ],
    type: type,
    //规则
    rules: {
        merchantId: [{ required: true, message: '请选择商户', trigger: 'blur' }],
        count: [{ required: true, message: '请输入正确的二维码数量', trigger: 'blur', pattern: integer }]
    },
    //数字单位
    numUnit: [
        {
            value: 1,
            label: '个'
        },
        {
            value: 10000,
            label: '万'
        },
        {
            value: 100000,
            label: '十万'
        },
        {
            value: 1000000,
            label: '百万'
        },
        {
            value: 10000000,
            label: '千万'
        },
        {
            value: 100000000,
            label: '亿'
        }
    ]
};

